import React, { Component } from 'react';
import {
    BellOutlined
} from '@ant-design/icons';
import { Menu, Dropdown, Tabs, Tag } from 'antd';
import config from 'src/commons/config-hoc';
import './style.less';

import MsgInfo from './msgInfo';
const { TabPane } = Tabs;

@config({ ajax: true })
export default class HeaderNotice extends Component {
    state = {
        loading: false,
        total: 0,           // 分页中条数
        pageNum: 1,         // 分页当前页
        pageSize: 10,       // 分页每页显示条数
        flag: true,
        visible: false,
        msgTotal: 0,
        msgList: [],
        selectMsg: null
    };

    componentDidMount() {
        this.handleSearch()
        this.getMsg()
    }

    getMsg = () => {
        this.props.ajax.get("/msg/sysmsgsend/my-notread/msg/page").then((res) => {
            if (res.data) {
                if (!res.data.total) {
                    res.data.total = 0
                }
                this.setState({ msgTotal: res.data.total < 99 ? res.data.total : 99 });
            }
        });
    };

    delMsg = (id) => {
        this.props.ajax.get("/msg/sysmsgread/" + id).then((res) => {
            if (res.data) {
                console.log(res.data)
            }
        });
    };

    callback = (key) => {
        console.log(key);
    }

    selectMsgItem = (item) => {
        // this.setState({visible:true,selectMsg:item})


        this.setState({ loading: true });
        if (item.isRead === 0) {
            this.props.ajax.post("/msg/sysmsgread/read/" + item.id).then(() => {
                setTimeout(() => {
                    this.setState({ pageNum: 1, msgList: [] }, () => { this.handleSearch() })
                }, 0)
            })
                .finally(() => this.setState({ loading: false }));
        }

    }
    readAll = (list) => {

        list.forEach((item, index) => {
            if (item.isRead === 0) {
                this.props.ajax.post("/msg/sysmsgread/read/" + item.id).then(() => {})
                if (index === list.length - 1) {
                    setTimeout(() => {
                        this.setState({ pageNum: 1, msgList: [] }, () => { this.handleSearch() })
                    }, 0)
                }
            }
        })

    }
    
    handleSearch = async () => {
        if (this.state.loading) return;


        const { pageNum, pageSize } = this.state;
        const params = {

            current: pageNum,
            size: pageSize,
        };

        this.setState({ loading: true });
        this.props.ajax.get('/msg/sysmsgsend/my-all/msg/page', params)
            .then(res => {
                if (res.data) {
                    if (!res.data.total) {
                        res.data.total = 0
                    }
                    let msgLists = this.state.msgList;
                    let newData = msgLists.concat(res?.data?.records || []);
                    let nxetPage = ++this.state.pageNum
                    this.setState({ msgList: newData, pageNum: nxetPage, flag: nxetPage <= res.data.pages }, this.getMsg);


                }


            })
            .finally(() => this.setState({ loading: false }));
    };

    loadTag = (val) => {
        switch (val) {
            case "1":
                return <Tag color="magenta">个人消息</Tag>
            case "2":
                return <Tag color="orange">调度消息</Tag>
            case "3":
                return <Tag color="green">计划消息</Tag>
            case "4":
                return <Tag color="blue">系统消息</Tag>
            case "5":
                return <Tag color="purple">代理消息</Tag>
            case "6":
                return <Tag color="red">流程任务消息</Tag>
            default:
                return ''
        }
    }

    render() {
        const { msgTotal, msgList, visible, selectMsg, flag } = this.state

        const menu = (
            <Menu styleName="menu">

                <div style={{ width: '336px' }}>
                    <Tabs defaultActiveKey="1" size="large" centered onChange={this.callback}>
                        <TabPane tab="消息通知" key="1" >
                            {/* {
                                msgTotal === 0 ? <div styleName="no-msg">
                                    暂无消息
                                </div> : null
                            } */}

                            <div styleName="msg-list">

                                {
                                    msgList.map((item, index) => {
                                        return <div styleName="msg-item" key={index} onClick={() => { this.selectMsgItem(item) }}>
                                            <div styleName="msg-title" title={item.subject}>{this.loadTag(item.messageType)}{item.subject} {item.isRead === 0 ? <span styleName="msg-no-read">未读</span> : <span styleName="msg-read">已读</span>}</div>
                                            <div styleName="msg-content" title={item.content}>{item.content}</div>
                                            <div styleName="msg-date">{item.createTime}
                                                {/* <span styleName="msg-del" onClick={()=>{this.delMsg(item.id)}}>
                                                    删除</span> */}
                                            </div>

                                        </div>
                                    })
                                }


                            </div>
                            <div styleName="tab-footer">
                                <div styleName="footer-left" onClick={() => {
                                    this.readAll(msgList)
                                }}>一键已读</div>
                                <div styleName="footer-right" onClick={() => {
                                    if (flag) {
                                        this.handleSearch()
                                    }
                                }}>{flag ? "查看更多" : "已加载完"}</div>
                            </div>
                        </TabPane>
                        {/* <TabPane tab="流程代办" key="2">
                            <div styleName="tab-footer">
                                <div styleName="footer-left">一键清空</div>
                                <div styleName="footer-right">查看更多</div>
                            </div>
                        </TabPane> */}
                    </Tabs>
                </div>
            </Menu>
        );

        return (
            <div styleName="notice">
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <BellOutlined styleName="notice-icon" />
                        {msgTotal === 0 ? null : <div styleName="numbers">
                            {msgTotal === 99 ? "99⁺" : msgTotal}
                        </div>}

                    </a>
                </Dropdown>
                <MsgInfo
                    visible={visible}
                    selectMsg={selectMsg}
                    onOk={() => this.setState({ visible: false }, this.getMsg)}
                    onCancel={() => this.setState({ visible: false })}
                />
            </div>
        );
    }
}

