// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/bpm/process/index',
    '/bpm/runinfo/index',
    '/login',
    '/taocan'
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login',
    '/taocan'
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/taocan',
        keepAlive: false,
    }
];

// 页面路由配置
export default [
    {
        path: '/admin/dept/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/dept/index.jsx'),
    },
    {
        path: '/admin/dict/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/dict/index.jsx'),
    },
    {
        path: '/admin/file/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/file/index.jsx'),
    },
    {
        path: '/admin/log/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/log/index.jsx'),
    },
    {
        path: '/admin/menu/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/menu/index.jsx'),
    },
    {
        path: '/admin/role/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/role/index.jsx'),
    },
    {
        path: '/admin/route/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/route/index.jsx'),
    },
    {
        path: '/admin/social/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/social/index.jsx'),
    },
    {
        path: '/admin/sysadcd/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/sysadcd/index.jsx'),
    },
    {
        path: '/admin/tenant/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/tenant/index.jsx'),
    },
    {
        path: '/admin/user/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/admin/users/index.jsx'),
    },
    {
        path: '/bpm/agentcondition/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/agentcondition/index.jsx'),
    },
    {
        path: '/bpm/agentdef/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/agentdef/index.jsx'),
    },
    {
        path: '/bpm/agentsetting/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/agentsetting/index.jsx'),
    },
    {
        path: '/bpm/approvalitem/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/approvalitem/index.jsx'),
    },
    {
        path: '/bpm/instance/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/bpmmanage/instance/index.jsx'),
    },
    {
        path: '/bpm/task/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/bpmmanage/task/index.jsx'),
    },
    {
        path: '/bpm/buslink/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/buslink/index.jsx'),
    },
    {
        path: '/bpm/commureceiver/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/commureceiver/index.jsx'),
    },
    {
        path: '/bpm/defact/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/defact/index.jsx'),
    },
    {
        path: '/bpm/defauthorize/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/defauthorize/index.jsx'),
    },
    {
        path: '/bpm/defauthtype/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/defauthtype/index.jsx'),
    },
    {
        path: '/bpm/defrights/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/defrights/index.jsx'),
    },
    {
        path: '/bpm/defuser/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/defuser/index.jsx'),
    },
    {
        path: '/bpm/defvars/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/defvars/index.jsx'),
    },
    {
        path: '/bpm/exestack/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/exestack/index.jsx'),
    },
    {
        path: '/bpm/flowcategroy/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/flowcategroy/index.jsx'),
    },
    {
        path: '/bpm/flowdefinition/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/flowdefinition/index.jsx'),
    },
    {
        path: '/bpm/formrun/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/formrun/index.jsx'),
    },
    {
        path: '/bpm/gangedset/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/gangedset/index.jsx'),
    },
    {
        path: '/bpm/leavebill/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/leavebill/index.jsx'),
    },
    {
        path: '/bpm/mondeptrole/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/mondeptrole/index.jsx'),
    },
    {
        path: '/bpm/mongroup/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/mongroup/index.jsx'),
    },
    {
        path: '/bpm/mongroupitem/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/mongroupitem/index.jsx'),
    },
    {
        path: '/bpm/alreadymatters/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/mycenter/alreadymatters/index.jsx'),
    },
    {
        path: '/bpm/completematters/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/mycenter/completematters/index.jsx'),
    },
    {
        path: '/bpm/mycomplete/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/mycenter/mycomplete/index.jsx'),
    },
    {
        path: '/bpm/myrequest/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/mycenter/myrequest/index.jsx'),
    },
    {
        path: '/bpm/nodebtn/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodebtn/index.jsx'),
    },
    {
        path: '/bpm/nodemessage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodemessage/index.jsx'),
    },
    {
        path: '/bpm/nodeprivilege/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodeprivilege/index.jsx'),
    },
    {
        path: '/bpm/noderule/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/noderule/index.jsx'),
    },
    {
        path: '/bpm/nodescript/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodescript/index.jsx'),
    },
    {
        path: '/bpm/nodeset/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodeset/index.jsx'),
    },
    {
        path: '/bpm/nodesign/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodesign/index.jsx'),
    },
    {
        path: '/bpm/nodeuser/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodeuser/index.jsx'),
    },
    {
        path: '/bpm/nodewebservice/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodewebservice/index.jsx'),
    },
    {
        path: '/bpm/nodewsparams/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/nodewsparams/index.jsx'),
    },
    {
        path: '/bpm/printtemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/printtemplate/index.jsx'),
    },
    {
        path: '/bpm/processcpto/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/processcpto/index.jsx'),
    },
    {
        path: '/bpm/processrun/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/processrun/index.jsx'),
    },
    {
        path: '/bpm/processrunhis/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/processrunhis/index.jsx'),
    },
    {
        path: '/bpm/processstatus/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/processstatus/index.jsx'),
    },
    {
        path: '/bpm/processtransto/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/processtransto/index.jsx'),
    },
    {
        path: '/bpm/referdefinition/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/referdefinition/index.jsx'),
    },
    {
        path: '/bpm/runlog/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/runlog/index.jsx'),
    },
    {
        path: '/bpm/subtablerights/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/subtablerights/index.jsx'),
    },
    {
        path: '/bpm/taskexe/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/taskexe/index.jsx'),
    },
    {
        path: '/bpm/taskfork/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/taskfork/index.jsx'),
    },
    {
        path: '/bpm/taskopinion/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/taskopinion/index.jsx'),
    },
    {
        path: '/bpm/taskread/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/taskread/index.jsx'),
    },
    {
        path: '/bpm/taskremind/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/taskremind/index.jsx'),
    },
    {
        path: '/bpm/taskreminderstate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/taskreminderstate/index.jsx'),
    },
    {
        path: '/bpm/tksigndata/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/tksigndata/index.jsx'),
    },
    {
        path: '/bpm/todo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/todo/index.jsx'),
    },
    {
        path: '/bpm/process/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/todo/processIndex.jsx'),
    },
    {
        path: '/bpm/runinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/todo/runInfoIndex.jsx'),
    },
    {
        path: '/bpm/usercondition/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/bpm/usercondition/index.jsx'),
    },
    {
        path: '/charts',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/chart/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/example/table-editable',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/examples/table-editable/index.jsx'),
    },
    {
        path: '/form/datatemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/datatemplate/index.jsx'),
    },
    {
        path: '/form/formcategroy/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formcategroy/index.jsx'),
    },
    {
        path: '/form/formdef/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formdef/index.jsx'),
    },
    {
        path: '/form/formdefhi/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formdefhi/index.jsx'),
    },
    {
        path: '/form/formdialog/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formdialog/index.jsx'),
    },
    {
        path: '/form/formfield/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formfield/index.jsx'),
    },
    {
        path: '/form/formquery/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formquery/index.jsx'),
    },
    {
        path: '/form/formrights/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formrights/index.jsx'),
    },
    {
        path: '/form/formrule/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formrule/index.jsx'),
    },
    {
        path: '/form/formtable/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formtable/index.jsx'),
    },
    {
        path: '/form/formtemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/formtemplate/index.jsx'),
    },
    {
        path: '/form/printtemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/printtemplate/index.jsx'),
    },
    {
        path: '/form/sysdatasource/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/sysdatasource/index.jsx'),
    },
    {
        path: '/form/tabletemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/form/tabletemplate/index.jsx'),
    },
    {
        path: '/gen/datasource',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/gen/datasource/index.jsx'),
    },
    {
        path: '/gen/formily',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/gen/formily/index.jsx'),
    },
    {
        path: '/gen/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/gen/gen/index.jsx'),
    },
    {
        path: '/gen',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/gen/index.jsx'),
    },
    {
        path: '/',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/home/index.jsx'),
    },
    {
        path: '/hooks-test',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/hooks-test/index.jsx'),
    },
    {
        path: '/react-use',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/hooks-test/ReactUse.jsx'),
    },
    {
        path: '/hooks-callback',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/hooks-test/UseCallback.jsx'),
    },
    {
        path: '/hooks-effect',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/hooks-test/UseEffect.jsx'),
    },
    {
        path: '/use-state',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/hooks-test/UseState.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/iframe/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/login/index.jsx'),
    },
    {
        path: '/taocan',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/login/setmeal/taocan.jsx'),
    },
    {
        path: '/menu-permission',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/org/deptgrade/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/org/deptgrade/index.jsx'),
    },
    {
        path: '/org/sysdept/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/org/sysdept/index.jsx'),
    },
    {
        path: '/org/sysjob/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/org/sysjob/index.jsx'),
    },
    {
        path: '/org/syspos/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/org/syspos/index.jsx'),
    },
    {
        path: '/pay/paychannel/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/pay/paychannel/index.jsx'),
    },
    {
        path: '/pay/goods/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/pay/paygoodsorder/index.jsx'),
    },
    {
        path: '/pay/notify/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/pay/paynotifyrecord/index.jsx'),
    },
    {
        path: '/pay/orders/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/pay/paytradeorder/index.jsx'),
    },
    {
        path: '/roles',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/role/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/setting/index.jsx'),
    },
    {
        path: '/bpm/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/activiti/index.jsx'),
    },
    {
        path: '/bpm/officialsealmanager/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/activiti/officialsealmanager/index.jsx'),
    },
    {
        path: '/bpm/process',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/activiti/process/index.jsx'),
    },
    {
        path: '/bpm/task',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/activiti/task/index.jsx'),
    },
    {
        path: '/assessment/manage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/assessment/assessmentManage/index.jsx'),
    },
    {
        path: '/assessment/assessresult/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/assessment/assessresult/index.jsx'),
    },
    {
        path: '/assessment/assessresultsrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/assessment/assessresultsrecord/index.jsx'),
    },
    {
        path: '/assessment/assessscorelevel/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/assessment/assessscorelevel/index.jsx'),
    },
    {
        path: '/assessment/assesstemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/assessment/assesstemplate/index.jsx'),
    },
    {
        path: '/assessment/unionassessment/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/assessment/unionassessment/index.jsx'),
    },
    {
        path: '/care/bedareainfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/bedareainfo/index.jsx'),
    },
    {
        path: '/care/bedinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/bedinfo/index.jsx'),
    },
    {
        path: '/care/careassessresult/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/careassessresult/index.jsx'),
    },
    {
        path: '/care/careassesstemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/careassesstemplate/index.jsx'),
    },
    {
        path: '/care/careplan/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/careplan/index.jsx'),
    },
    {
        path: '/care/carescorelevel/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/carescorelevel/index.jsx'),
    },
    {
        path: '/care/changeshifts/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/changeshifts/index.jsx'),
    },
    {
        path: '/care/checkin/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/checkin/index.jsx'),
    },
    {
        path: '/care/eldersinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/eldersinfo/index.jsx'),
    },
    {
        path: '/care/familyvisit/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/familyvisit/index.jsx'),
    },
    {
        path: '/care/jobscheduling/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/jobscheduling/index.jsx'),
    },
    {
        path: '/care/jobschedulingstaff/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/jobschedulingstaff/index.jsx'),
    },
    {
        path: '/assessment/mesureitems/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/mesureitems/index.jsx'),
    },
    {
        path: '/assessment/mesureresultrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/mesureresultrecord/index.jsx'),
    },
    {
        path: '/care/nurseitem/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/nurseitem/index.jsx'),
    },
    {
        path: '/care/nursestandard/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/nursestandard/index.jsx'),
    },
    {
        path: '/care/statistics/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/statistics/index.jsx'),
    },
    {
        path: '/care/teammanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/care/teammanage/index.jsx'),
    },
    {
        path: '/common/activityinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/activityinfo/index.jsx'),
    },
    {
        path: '/common/appversion/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/appversion/index.jsx'),
    },
    {
        path: '/common/custadvise/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/custadvise/index.jsx'),
    },
    {
        path: '/common/diseasehistory/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/diseasehistory/index.jsx'),
    },
    {
        path: '/common/eldersdailyrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/eldersdailyrecord/index.jsx'),
    },
    {
        path: '/common/expensereimbursement/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/expensereimbursement/index.jsx'),
    },
    {
        path: '/common/fullinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/full-info/index.jsx'),
    },
    {
        path: '/common/goodbuy/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/goodbuy/index.jsx'),
    },
    {
        path: '/common/informationdelivery/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/informationdelivery/index.jsx'),
    },
    {
        path: '/marketinventory/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/marketinventory/index.jsx'),
    },
    {
        path: '/common/medicationhistory/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/medicationhistory/index.jsx'),
    },
    {
        path: '/common/meetingarrange/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/meetingarrange/index.jsx'),
    },
    {
        path: '/common/memberbuylist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/memberbuylist/index.jsx'),
    },
    {
        path: '/post/memberbuylist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/memberbuylist/postIndex.jsx'),
    },
    {
        path: '/common/memberinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/memberinfo/index.jsx'),
    },
    {
        path: '/post/postmemberinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/memberinfo/postIndex.jsx'),
    },
    {
        path: '/common/membershiptype/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/membershiptype/index.jsx'),
    },
    {
        path: '/post/membershiptype/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/membershiptype/postIndex.jsx'),
    },
    {
        path: '/common/monthplandetails/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/monthplandetails/index.jsx'),
    },
    {
        path: '/common/monthworkplan/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/monthworkplan/index.jsx'),
    },
    {
        path: '/common/propagandainfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/propagandainfo/index.jsx'),
    },
    {
        path: '/common/residentialbuilding/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/residentialbuilding/index.jsx'),
    },
    {
        path: '/common/serviceempower/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/serviceempower/index.jsx'),
    },
    {
        path: '/common/servicepool/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/servicepool/index.jsx'),
    },
    {
        path: '/common/staffcertificates/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/staffcertificates/index.jsx'),
    },
    {
        path: '/common/staffinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/staffinfo/index.jsx'),
    },
    {
        path: '/common/staffmanual/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/staffmanual/index.jsx'),
    },
    {
        path: '/common/surgicaltraumahistory/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/surgicaltraumahistory/index.jsx'),
    },
    {
        path: '/common/threeservicecompany/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/common/threeservicecompany/index.jsx'),
    },
    {
        path: '/custody/abnormalpushconfig/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/abnormalpushconfig/index.jsx'),
    },
    {
        path: '/custody/alarmStatistics/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/alarmStatistics/index.jsx'),
    },
    {
        path: '/custody/areainfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/areainfo/index.jsx'),
    },
    {
        path: '/custody/callpoliceinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/callpoliceinfo/index.jsx'),
    },
    {
        path: '/custody/changrefundlist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/changrefundlist/index.jsx'),
    },
    {
        path: '/custody/custadvise/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/custadvise/index.jsx'),
    },
    {
        path: '/custody/custodyelder/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/custodyelders/index.jsx'),
    },
    {
        path: '/custody/devicelist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/devicelist/index.jsx'),
    },
    {
        path: '/custody/devicemanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/devicemanage/index.jsx'),
    },
    {
        path: '/common/eldersappuser/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/eldersappuser/index.jsx'),
    },
    {
        path: '/custody/elderStatiatics/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/elderStatistics/index.jsx'),
    },
    {
        path: '/custody/equipmentarea/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/equipmentarea/index.jsx'),
    },
    {
        path: '/custody/equipmentinterfacearray/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/equipmentinterfacearray/index.jsx'),
    },
    {
        path: '/custody/equipmentiotstatus/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/equipmentiotstatus/index.jsx'),
    },
    {
        path: '/custody/equipmentmanufactor/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/equipmentmanufactor/index.jsx'),
    },
    {
        path: '/custody/equipmentstartstoplist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/equipmentstartstoplist/index.jsx'),
    },
    {
        path: '/custody/goodscategory/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/goodscategory/index.jsx'),
    },
    {
        path: '/custody/healthalert/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/healthalert/index.jsx'),
    },
    {
        path: '/custody/interfacebuttonrelation/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/interfacebuttonrelation/index.jsx'),
    },
    {
        path: '/custody/manufacturer/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/manufacturer/index.jsx'),
    },
    {
        path: '/custody/promoteactivity/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/promoteactivity/index.jsx'),
    },
    {
        path: '/custody/requesttoken/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/requesttoken/index.jsx'),
    },
    {
        path: '/custody/servicepack/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/servicepack/index.jsx'),
    },
    {
        path: '/custody/servicepool/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/servicepool/index.jsx'),
    },
    {
        path: '/custody/shopchat/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/shopchat/index.jsx'),
    },
    {
        path: '/custody/shopchatrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/shopchat/shopchatrecord/index.jsx'),
    },
    {
        path: '/custody/shopgoods/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/shopgoods/index.jsx'),
    },
    {
        path: '/custody/shopgoodsorder/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/shopgoodsorder/index.jsx'),
    },
    {
        path: '/custory/shoppingcart/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/shoppingcart/index.jsx'),
    },
    {
        path: '/custody/stopalarmmsg/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/stopalarmmsg/index.jsx'),
    },
    {
        path: '/custody/videoManage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/custody/videoManage/index.jsx'),
    },
    {
        path: '/finance/accountexpenditure/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/accountexpenditure/index.jsx'),
    },
    {
        path: '/finance/arrearsremind/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/arrearsremind/index.jsx'),
    },
    {
        path: '/finance/contract/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/contractcost/index.jsx'),
    },
    {
        path: '/finance/costset/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/costset/index.jsx'),
    },
    {
        path: '/finance/deductmoneyapplyfor/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/deductmoneyapplyfor/index.jsx'),
    },
    {
        path: '/finance/exitsettlement/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/elderaccount/exitsettlement/index.jsx'),
    },
    {
        path: '/finance/elderaccount/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/elderaccount/index.jsx'),
    },
    {
        path: '/finance/elderdeductionset/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/elderdeductionset/index.jsx'),
    },
    {
        path: '/finance/eldermonthlybill/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/eldermonthlybill/index.jsx'),
    },
    {
        path: '/finance/expenditureStatistics/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/expenditureStatistics/index.jsx'),
    },
    {
        path: '/finance/total/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/index.jsx'),
    },
    {
        path: '/finance/invoicereceipt/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/invoicereceipt/index.jsx'),
    },
    {
        path: '/finance/maintenancepay/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/maintenancepay/index.jsx'),
    },
    {
        path: '/finance/paindin/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/paidinaccount/paidindetail.jsx'),
    },
    {
        path: '/finance/policydiscountactivity/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/policydiscountactivity/index.jsx'),
    },
    {
        path: '/finance/precharge/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/finance/precharge/index.jsx'),
    },
    {
        path: '/general/achievementsbucklededuct/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/achievementsbucklededuct/index.jsx'),
    },
    {
        path: '/general/archivesfilegrant/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/archivesfilegrant/index.jsx'),
    },
    {
        path: '/general/archivesmanager/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/archivesmanager/index.jsx'),
    },
    {
        path: '/general/blacklist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/blacklist/index.jsx'),
    },
    {
        path: '/general/certificatesannualreview/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/certificatesannualreview/index.jsx'),
    },
    {
        path: '/general/checkabnormalcount/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/checkabnormalcount/index.jsx'),
    },
    {
        path: '/general/checkabnormalrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/checkabnormalrecord/index.jsx'),
    },
    {
        path: '/general/comparisonresultrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/comparisonresultrecord/index.jsx'),
    },
    {
        path: '/general/expertinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/expertinfo/index.jsx'),
    },
    {
        path: '/general/externalpublicity/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/externalpublicity/index.jsx'),
    },
    {
        path: '/general/officialsealmanager/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/officialsealmanager/index.jsx'),
    },
    {
        path: '/general/staffleave/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/staffleave/index.jsx'),
    },
    {
        path: '/general/staffleavecount/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/staffleavecount/index.jsx'),
    },
    {
        path: '/general/staffwages/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/staffwages/index.jsx'),
    },
    {
        path: '/general/superviseaffairs/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/superviseaffairs/index.jsx'),
    },
    {
        path: '/general/supplycompany/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/supplycompany/index.jsx'),
    },
    {
        path: '/general/trainstaff/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/trainstaff/index.jsx'),
    },
    {
        path: '/general/writingmanager/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/general/writingmanager/index.jsx'),
    },
    {
        path: '/livable/categorykeyparts/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/categorykeyparts/index.jsx'),
    },
    {
        path: '/livable/comprehensivetotal/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/comprehensivetotal/index.jsx'),
    },
    {
        path: '/livable/electricalinspectionrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/electricalinspectionrecord/index.jsx'),
    },
    {
        path: '/livable/equipmentfacilities/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/equipmentfacilities/index.jsx'),
    },
    {
        path: '/livable/fireinspectionrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/fireinspectionrecord/index.jsx'),
    },
    {
        path: '/livable/indoorcleaninginspection/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/indoorcleaninginspection/index.jsx'),
    },
    {
        path: '/livable/indoorcleaningnormalization/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/indoorcleaningnormalization/index.jsx'),
    },
    {
        path: '/livable/investigationtemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/investigationtemplate/index.jsx'),
    },
    {
        path: '/livable/onecallrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/onecallrecord/index.jsx'),
    },
    {
        path: '/livable/outdoorcleaninginspection/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/outdoorcleaninginspection/index.jsx'),
    },
    {
        path: '/livable/outdoorcleaningnormalization/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/livable/outdoorcleaningnormalization/index.jsx'),
    },
    {
        path: '/lotte/activityinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/lotte/activityinfo/index.jsx'),
    },
    {
        path: '/lotte/eldersinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/lotte/eldersinfo/index.jsx'),
    },
    {
        path: '/nutrition/businessmeal/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/businessmeal/index.jsx'),
    },
    {
        path: '/nutrition/eldersdietcatalog/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/eldersdietcatalog/index.jsx'),
    },
    {
        path: '/nutrition/foodinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/foodinfo/index.jsx'),
    },
    {
        path: '/nutrition/foodingredients/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/foodingredients/index.jsx'),
    },
    {
        path: '/nutrition/foodmainingredients/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/foodmainingredients/index.jsx'),
    },
    {
        path: '/nutrition/foodnutrientloss/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/foodnutrientloss/index.jsx'),
    },
    {
        path: '/nutrition/foodnutritiveingredients/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/foodnutritiveingredients/index.jsx'),
    },
    {
        path: '/nutrition/foodtaboocrowd/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/foodtaboocrowd/index.jsx'),
    },
    {
        path: '/nutrition/materialtype/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/materialtype/index.jsx'),
    },
    {
        path: '/nutrition/nutrientscatalog/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/nutrientscatalog/index.jsx'),
    },
    {
        path: '/nutrition/orderingmael/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/ordering/index.jsx'),
    },
    {
        path: '/nutrition/purchasemanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/purchasemanage/index.jsx'),
    },
    {
        path: '/nutrition/setmealcountfool/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/setmeal/costStatistics.jsx'),
    },
    {
        path: '/nutrition/setmeal/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/setmeal/index.jsx'),
    },
    {
        path: '/nutrition/staffsetmealcountfool/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/staffmeal/costStatistics.jsx'),
    },
    {
        path: '/nutrition/staffmeal/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/staffmeal/staffmeal.jsx'),
    },
    {
        path: '/nutrition/supplier/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/nutrition/supplier/index.jsx'),
    },
    {
        path: '/post/achievementroyalty/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/achievementroyalty/index.jsx'),
    },
    {
        path: '/post/assets/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/assets/index.jsx'),
    },
    {
        path: '/post/elderstaglist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/elderstaglist/index.jsx'),
    },
    {
        path: '/post/expendbudget/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/expendbudget/index.jsx'),
    },
    {
        path: '/post/incomebudget/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/incomebudget/index.jsx'),
    },
    {
        path: '/post/monthlybudget/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/monthlybudget/index.jsx'),
    },
    {
        path: '/post/monthlybudgetlist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/monthlybudgetlist/index.jsx'),
    },
    {
        path: '/post/monthlybudgettemplate/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/monthlybudgettemplate/index.jsx'),
    },
    {
        path: '/post/monthlytemplatelist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/monthlytemplatelist/index.jsx'),
    },
    {
        path: '/post/ordermanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/ordermanage/index.jsx'),
    },
    {
        path: '/post/paydetailed/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/paydetailed/index.jsx'),
    },
    {
        path: '/post/postbusinessdaily/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/postbusinessdaily/index.jsx'),
    },
    {
        path: '/post/postincomelist/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/postincomelist/index.jsx'),
    },
    {
        path: '/post/postinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/postinfo/index.jsx'),
    },
    {
        path: '/post/postinfomoneycount/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/postinfo/moneyStatistics.jsx'),
    },
    {
        path: '/post/postmember/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/postmember/index.jsx'),
    },
    {
        path: '/post/promoterecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/promoterecord/index.jsx'),
    },
    {
        path: '/post/servicemanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/servicemanage/index.jsx'),
    },
    {
        path: '/post/tagconfig/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/post/tagconfig/index.jsx'),
    },
    {
        path: '/recovery/doctorhealthyconsult/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/doctorhealthyconsult/index.jsx'),
    },
    {
        path: '/recovery/doctorinterrogation/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/doctorinterrogation/index.jsx'),
    },
    {
        path: '/recovery/drugsmanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/drugsmanage/index.jsx'),
    },
    {
        path: '/recovery/eldersmanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/eldersmanage/index.jsx'),
    },
    {
        path: '/recovery/eldersmedication/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/eldersmedication/index.jsx'),
    },
    {
        path: '/recovery/recoveryappointmentexecute/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/recoveryappointmentexecute/index.jsx'),
    },
    {
        path: '/recovery/recoveryequipment/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/recoveryequipment/index.jsx'),
    },
    {
        path: '/recovery/replyrecord/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/recovery/replyrecord/index.jsx'),
    },
    {
        path: '/sale/assets/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/assets/index.jsx'),
    },
    {
        path: '/sale/checkin/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/checkin/index.jsx'),
    },
    {
        path: '/sale/contractmanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/contractmanage/index.jsx'),
    },
    {
        path: '/sale/discountactivity/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/discountactivity/index.jsx'),
    },
    {
        path: '/sale/eldersinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/eldersinfo/index.jsx'),
    },
    {
        path: '/common/familymember/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/familymember/index.jsx'),
    },
    {
        path: '/sale/guestreceive/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/guestreceive/index.jsx'),
    },
    {
        path: '/sale/leavemanage/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/leavemanage/index.jsx'),
    },
    {
        path: '/sale/orgreceive/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/orgreceive/index.jsx'),
    },
    {
        path: '/sale/questionnaireinfo/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/questionnaireinfo/index.jsx'),
    },
    {
        path: '/sale/questionnaireanswer/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/questionnaireinfo/questionnaireanswer/index.jsx'),
    },
    {
        path: '/sale/questionnairequestion/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/questionnaireinfo/questionnairequestion/index.jsx'),
    },
    {
        path: '/sale/questionnaireoption/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/sale/questionnaireinfo/questionnairequestion/questionnaireoption/index.jsx'),
    },
    {
        path: '/shopping/chatmsg/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/shopping/chatmsg/index.jsx'),
    },
    {
        path: '/shopping/servicestaff/index',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/slys/shopping/servicestaff/index.jsx'),
    },
    {
        path: '/users',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/users/index.jsx'),
    },
    {
        path: '/hook/users',
        component: () => import('D:/workspace/E360Group/ml-e360-service/project-code/html/e360/src/pages/users/UserListHooks.jsx'),
    }
];
    