import React, {Component} from 'react';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

export default class MyBraftEditor extends Component {

    state = {
        editorState: null
    }
  
    componentDidMount () {
      // Assume here to get the editor content in html format from the server
      // const htmlContent = fetchEditorContent()
      // Use BraftEditor.createEditorState to convert html strings to editorState data needed by the editor
      this.setState({
        editorState: BraftEditor.createEditorState(null)
      })
    }
  
    // submitContent = async () => {
    //   // Pressing ctrl + s when the editor has focus will execute this method
    //   // Before the editor content is submitted to the server, you can directly call editorState.toHTML () to get the HTML content
    //   const htmlContent = this.state.editorState.toHTML()
    //   const result = saveEditorContent(htmlContent)
    // }
  
    // handleEditorChange = (editorState) => {
    //   this.setState({ editorState })
    // }
  
    render () {
      const { onSave, onChange, ...others} = this.props;
      const { editorState } = this.state
      return (
        <div className="braft-editor-component">
          <BraftEditor
            value={editorState}
            onSave={onSave}
            onChange={onChange}
            {...others}
          />
        </div>
      )
    }
}
