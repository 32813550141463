import {ajax} from 'src/commons/ajax';

/*
* 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
* */
export async function getMenus() {
    const menus = await ajax.get(`/admin/menu`)
    return Promise.resolve(menus['data']);
}

/**
 * 后台菜单数据转换成数组
 * @param {} source 
 * @param {*} target 
 */
export function getMenuArray(source, target) {
    if(source.path !== null && source.path !== undefined && (source.path.indexOf('http://') !== -1 || source.path.indexOf('https://') !== -1)) {
        target.push({
            key: source['id'] + "",
            parentKey: source['parentId'] + "",
            icon: source['icon'],
            text: source['name'],
            path: source['path'],
            order: source['sort'],
            url:  source['path'],
            target: '_blank' 
        })
    } else {
        target.push({
            key: source['id'] + "",
            parentKey: source['parentId'] + "",
            icon: source['icon'],
            text: source['name'],
            path: source['path'],
            order: source['sort'] 
        })
    }
    
    if(source['children'] && source['children'].length > 0) {
        for(let i=0; i<source['children'].length; i++) {
            getMenuArray(source['children'][i], target)
        }
    }
}
