import React, { Component } from 'react';
import { Menu, Dropdown, Tabs } from 'antd';
import config from 'src/commons/config-hoc';
import './style.less';

@config({
    ajax: true, 
    modal: {
        title: "消息内容",
        width: "500px"
    },
})
export default class HeaderNotice extends Component {
    state = {
     
    };

    componentDidMount() {
      
    }




    render() {
        const { selectMsg } = this.props

  
        return (
            <div styleName="notice">
             
            </div>
        );
    }
}

