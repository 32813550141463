import React, { Component } from 'react'
import JSONEditor from 'jsoneditor'
 
import 'jsoneditor/dist/jsoneditor.css'
 
class JsonEditor extends Component {
  // 监听输入值的变化
  onChange = () => {
    let value = this.jsoneditor.get()
    this.props.onChange && this.props.onChange(value)
  }
  // 对外暴露获取编辑器的json数据
  getJson = () => {
    return this.jsoneditor.get()
  }
  // 对外提交错误信息
  onError = (errArr) => {
    this.props.onError && this.props.onError(errArr)
  }
 
  initJsonEditor = () => {
    const options = {
      mode: 'tree',
      history: true,
      onChange: this.onChange,
      onValidationError: this.onError
    };
 
    this.jsoneditor = new JSONEditor(this.container, options)
    this.jsoneditor.set(this.props.value)
  }
 
 
  componentDidMount () {
    this.initJsonEditor()
    // 设置主题色
    this.container.querySelector('.jsoneditor-menu').style.backgroundColor = this.props.themeBgColor
    this.container.querySelector('.jsoneditor').style.border = `thin solid ${this.props.themeBgColor}`
  }
 
  componentDidUpdate() {
    if(this.jsoneditor) {
      this.jsoneditor.update(this.props.value)
    }
  }
 
  render() {
    return (
      <div className="jsonEditWrap">
        <div className="jsoneditor-react-container" ref={elem => this.container = elem} />
      </div>
    );
  }
}
 
export default JsonEditor