import React, { Component } from 'react';
import { Select } from 'antd';
import { ajax } from 'src/commons/ajax';


export default class Index extends Component {

    state = {
        options: []
    };

    componentWillUnmount() {

    }

    componentDidMount = () => {
        const { typekey, isAll } = this.props;
        ajax.get(`/admin/dict/type/${typekey}`).then(res => {
            let options = []
            for (let i = 0; i < res['data'].length; i++) {
                options.push({
                    label: res['data'][i].label,
                    value: res['data'][i].value,
                    key: res['data'][i].value
                })
            }
            if (isAll) {
                options.push({
                    label: "全部",
                    value: '',
                    key: ''
                })
            }
            this.setState({
                options
            })
        })

    }

    render() {
        const { ...others } = this.props;
        return (
            <Select {...others}>
                {
                    this.state.options.map(opt => <Select.Option key={opt.value} {...opt}>{opt.label}</Select.Option>)
                }
            </Select>
        )
    }
}
