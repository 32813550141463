import React, {Component} from 'react';
import {
    CaretDownOutlined,
} from '@ant-design/icons';
import {Menu, Dropdown} from 'antd';
import {getLoginUser, getUserDepts,setLoginUser, toHome} from 'src/commons';
import config from 'src/commons/config-hoc';
import './style.less';


const Item = Menu.Item;

@config({ajax: true})
export default class HeaderUserDepts extends Component {
    static defaultProps = {
        theme: 'default',
    };

    state = {
        passwordVisible: false,
    };

    handleMenuClick = ({key}) => {
        const user = getLoginUser() || {};
        const deptId = user['sysUser']['deptId'];
        if(key === deptId) {
            return ;
        }
        this.props.ajax.get("/admin/userdepts/" + key).then(res=>{
            if(res['data']) {
                this.props.ajax.get(`/admin/user/info`).then(res => {
                    this.props.action.system.setPermissions(res['data']['permissions'])
                    this.props.action.system.closeAllTabs()
                    setLoginUser(res['data'])
                }).then(()=> {
                    this.props.ajax.get(`/admin/userdepts`).then(res => {
                        toHome();
                    })
                })
            }
        })
    };

    render() {
        const userDeptsPre = getUserDepts() || []
        let userDepts = [];
        let userDeptObj = {}
        for(let i=0; i<userDeptsPre.length; i++) {
            if(userDeptObj[userDeptsPre[i]['deptId']] == null || userDeptObj[userDeptsPre[i]['deptId']] == undefined) {
                userDeptObj[userDeptsPre[i]['deptId']] = userDeptsPre[i];
                userDepts.push(userDeptsPre[i]);
            }
        }
        if(userDepts.length < 2) {
            return null;
        }

        const user = getLoginUser() || {};
        const deptId = user['sysUser']['deptId'];
        let menuTitle = '';
        let menuItems = []
        userDepts.map(item=>{
            if(item.deptId === deptId)
                menuTitle = item['deptName'];
            menuItems.push(<Item key={item.deptId}>{item['deptName']}</Item>);
            return item;
        })
        const {className, theme} = this.props;

        const menu = (
            <Menu styleName="menu" theme={theme} selectedKeys={[]} onClick={this.handleMenuClick}>
                {menuItems}
            </Menu>
        );
        return (
            <div styleName="dept-menu" ref={node => this.userMenu = node}>
                <Dropdown trigger="click" overlay={menu} getPopupContainer={() => (this.userMenu || document.body)}>
                    <span styleName="account" className={className}>
                        <span styleName="dept-name">{menuTitle}</span>
                        <CaretDownOutlined/>
                    </span>
                </Dropdown>
            </div>
        );
    }
}
